// components/CompassPage.tsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Select,
  UnorderedList,
  ListItem,
  VStack,
  Divider,
  Heading,
  Flex,
} from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

interface Contribution {
  category: string;
  contrib_type: string;
  description: string;
  substant_contrib_criteria: string;
  dnsh: {
    [key: string]: string;
  };
  footnotes: string;
}

interface Activity {
  name: string;
  sector: string;
  nace: string;
  contributions: Contribution[];
}

interface TaxonomyData {
  sectors: string[];
  activities: Activity[];
}

const CompassPage: React.FC = () => {
  const [taxonomyData, setTaxonomyData] = useState<TaxonomyData>({
    sectors: [],
    activities: [],
  });
  const [selectedSector, setSelectedSector] = useState<string>('');
  const [selectedActivity, setSelectedActivity] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [filteredActivities, setFilteredActivities] = useState<string[]>([]);
  const [activityDetails, setActivityDetails] = useState<Contribution[]>([]);

  useEffect(() => {
    fetch('/taxonomy_compass.json')
      .then((response) => response.json())
      .then((data) => setTaxonomyData(data))
      .catch((error) => console.error('Error fetching taxonomy data:', error));
  }, []);

  useEffect(() => {
    if (selectedSector && taxonomyData.activities) {
      const activities = taxonomyData.activities
        .filter((activity) => activity.sector === selectedSector)
        .map((activity) => activity.name);
      setFilteredActivities(activities);
    } else {
      setFilteredActivities(
        taxonomyData.activities.map((activity) => activity.name) || []
      );
    }
  }, [selectedSector, taxonomyData.activities]);

  useEffect(() => {
    if (selectedActivity && taxonomyData.activities) {
      const selectedActivityDetails = taxonomyData.activities.find(
        (activity) => activity.name === selectedActivity
      );

      if (selectedActivityDetails) {
        setActivityDetails(selectedActivityDetails.contributions);

        if (selectedActivityDetails.contributions.length > 0) {
          setSelectedCategory(selectedActivityDetails.contributions[0].category);
        }
      }
    }
  }, [selectedActivity, taxonomyData.activities]);

  const handleSectorChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedSector(event.target.value);
    setSelectedActivity('');
    setActivityDetails([]);
    setSelectedCategory('');
  };

  const handleActivityChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedActivity(event.target.value);
    setActivityDetails([]);
    setSelectedCategory('');
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedCategory(event.target.value);
  };

  const renderDetail = (detail: Contribution) => {
    return (
      <VStack align="start" spacing={2} key={detail.category} pt={4} pb={4}>
        {detail.contrib_type && (
          <>
            <Heading as={'h4'} fontSize={'xl'}>
              Contribution type:
            </Heading>
            <Text>{detail.contrib_type ?? 'Not defined'}</Text>
          </>
        )}
        <Box>
          <Heading as={'h4'} fontSize={'xl'} mt={8} pb={3}>
            Description:
          </Heading>
          <Box>
            <ReactMarkdown>{detail.description}</ReactMarkdown>
          </Box>
          <Divider m={6} />
          <Heading as={'h4'} fontSize={'xl'} pt={2} pb={3}>
            Substantial contribution criteria:
          </Heading>
          <Box>
            <Text>{detail.substant_contrib_criteria}</Text>
          </Box>
          {detail.dnsh &&
            Object.keys(detail.dnsh).map((dnshKey) => (
              <Box key={dnshKey}>
                <Divider m={6} />
                <Heading as={'h5'} pb={2} fontSize={'xl'}>
                  {dnshKey}:
                </Heading>
                <Text>{detail.dnsh[dnshKey]}</Text>
              </Box>
            ))}
          {detail.footnotes && (
            <Box>
              <Divider m={4} />
              <Heading as={'h4'} pb={2} pt={2} fontSize={'xl'}>
                Footnotes:
              </Heading>
              <Text>{detail.footnotes}</Text>
            </Box>
          )}
        </Box>
      </VStack>
    );
  };

  return (
    <Flex
      width={'80%'}
      margin={'auto'}
      flexDirection={'column'}
      pt="16"
      pb="16"
    >
      <Box justifySelf={'start'}>
        <Text fontSize="lg">Select Sector and Activity:</Text>
        <Select
          placeholder="Select sector"
          value={selectedSector}
          onChange={handleSectorChange}
          margin={'4'}
          width={'640px'}
        >
          {taxonomyData.sectors.map((sector) => (
            <option key={sector} value={sector}>
              {sector}
            </option>
          ))}
        </Select>
        <Select
          placeholder="Select activity"
          value={selectedActivity}
          onChange={handleActivityChange}
          margin={'4'}
          width={'640px'}
        >
          {filteredActivities.map((activity) => (
            <option key={activity} value={activity}>
              {activity}
            </option>
          ))}
        </Select>

        <Select
          placeholder="Select category"
          value={selectedCategory}
          onChange={handleCategoryChange}
          margin={'4'}
          width={'640px'}
        >
          {activityDetails.map((contribution) => (
            <option key={contribution.category} value={contribution.category}>
              {contribution.category}
            </option>
          ))}
        </Select>
      </Box>

      {activityDetails
        .filter((contribution) => contribution.category === selectedCategory)
        .map((contribution) => (
          <Box key={contribution.category} mt="8">
            <UnorderedList styleType="none">
              <ListItem key={contribution.category}>
                <Heading as={'h3'} fontWeight="bold" fontSize={'3xl'}>
                  {contribution.category}
                </Heading>
                <Divider m={4} />
                {renderDetail(contribution)}
              </ListItem>
            </UnorderedList>
          </Box>
        ))}
    </Flex>
  );
};

export default CompassPage;
