import React from "react";
import { Box, Divider, Flex, Input, Spacer, Text } from "@chakra-ui/react";
import { ArrowRightIcon } from "@chakra-ui/icons";
import ControlElement from "./ControlElement";
import { Message, Conversation } from "../../context/ChatsContext";
import { estimateCosts, estimateCostsForInput, estimateCostsForOutput } from '../../utils/costEstimation';

interface ContextInfoProps {
  currentConversation: Conversation | null
}

const ContextInfo: React.FC<ContextInfoProps> = ({ currentConversation }) => {

  const [outputEstimate, setOutputEstimate] = React.useState(800);


  return <ControlElement title='Beantwortungskosten'>
    <Box fontSize={"sm"}>
      <Flex justifyContent={"space-between"} alignItems={"center"} textColor={"gray"}>
        {currentConversation?.messages.length} Nachrichten insgesamt
        <ArrowRightIcon fontSize={"sm"} />
        {currentConversation?.totalTokenCount ?? 0} Tokens
      </Flex>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        {currentConversation?.messages.filter((msg: Message) => msg.active).length} Nachrichten aktiv
        <ArrowRightIcon fontSize={"sm"} />
        {currentConversation?.activeTokenCount ?? 0} Tokens
      </Flex>

      {
        currentConversation?.activeTokenCount &&
          currentConversation?.activeTokenCount > 120000 ?
          <Text color={"red"} fontSize={"sm"}
            mt={"2"}
          >
            Warnung: Dieser Chat ist zu lange, um befragt zu werden!
            Deaktivieren oder löschen Sie einige Nachrichten, um unter 120000 Tokens zu kommen!
          </Text>
          : null
      }
      <></>

      <Divider borderColor={'black'} mt="2" mb="2" />

      <Text>Cost Estimation:</Text>
      <Divider borderColor={'black'} mt="0.5" mb="0.5" />

      <Flex alignItems={"center"}>
        <Text w={"3.5em"}>Input: </Text>
        <Spacer /><ArrowRightIcon fontSize={"sm"} /><Spacer />
        <Text w={"6em"} textAlign={"center"}> {currentConversation?.activeTokenCount ?? 0} </Text>
        <Spacer /><ArrowRightIcon fontSize={"sm"} /><Spacer />
        <Text>{estimateCostsForInput(currentConversation?.activeTokenCount ?? 0)} €</Text>
      </Flex>

      <Flex alignItems={"center"}>
        <Text w={"3.5em"}>Output: </Text>
        <Spacer /><ArrowRightIcon fontSize={"sm"} /><Spacer />
        <Input
          size={"sm"}
          value={outputEstimate} onChange={(e) => {
            setOutputEstimate(parseInt(e.target.value));
          }} ml="0" mr="0" w={"6em"} h={"1.5em"} textAlign={"center"} />
        <Spacer /><ArrowRightIcon fontSize={"sm"} /><Spacer />
        <Text>{estimateCostsForOutput(outputEstimate)} €</Text>
      </Flex>
      <Divider borderColor={'black'} mt="1" mb="1" />
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Text>Total</Text>
        <Text>{estimateCosts(currentConversation?.activeTokenCount ?? 0, outputEstimate)} €</Text>
      </Flex>
    </Box>
  </ControlElement>;
};

export default ContextInfo;