import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Box, Button, Heading, VStack, HStack, useColorModeValue, Switch, Text } from '@chakra-ui/react';
import ChatSelectionElement from '../components/ChatSelectionElement';
import { useChat, Conversation } from '../context/ChatsContext';
import { AddIcon, TriangleUpIcon, TriangleDownIcon } from '@chakra-ui/icons';
import { useLogin } from '../context/LoginContext';
import { SortDirection, SortOption, useSorting } from '../context/SortingContext';


const ConversationsListPage: React.FC = () => {
  const { conversations, fetchConversations, createNewConversation } = useChat();
  const { loginData } = useLogin();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    sortOption, setSortOption,
    sortDirection, setSortDirection,
    showOnlyMyChats, setShowOnlyMyChats
  } = useSorting();

  const [newConversations, setNewConversations] = useState<Conversation[]>([]);

  const bgColor = useColorModeValue("light.background", "dark.background");
  const textColor = useColorModeValue("light.text", "dark.text");
  const buttonColor = useColorModeValue("light.buttonMain", "dark.buttonMain");
  const buttonHoverColor = useColorModeValue("light.buttonHover", "dark.buttonHover");
  const activeButtonColor = useColorModeValue("light.buttonActive", "dark.buttonActive");
  const inactiveButtonColor = useColorModeValue("light.buttonInactive", "dark.buttonInactive");

  useEffect(() => {
    const loadConversations = async () => {
      if (conversations.length === 0 && !isLoading) {
        setIsLoading(true);
        setError(null);
        try {
          await fetchConversations();
        } catch (err) {
          setError('Failed to load conversations. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadConversations();
  }, [fetchConversations, conversations.length, isLoading]);

  const onActionComplete = useCallback(() => {
    fetchConversations();
  }, [fetchConversations]);

  const handleCreateNewConversation = async () => {
    try {
      const newConversation = await createNewConversation();
      setNewConversations(prev => [newConversation, ...prev]);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error creating new conversation:', error);
      setError('Failed to create a new conversation. Please try again later.');
    }
  };

  const removeFromNewConversations = useCallback((conversationId: number) => {
    setNewConversations(prev => prev.filter(conv => conv.id !== conversationId));
  }, []);

  const handleSort = (newSortOption: SortOption) => {
    if (newSortOption === sortOption) {
      setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOption(newSortOption);
      setSortDirection('asc');
    }
  };

  const sortedAndFilteredConversations = useMemo(() => {
    console.log('Sorting and filtering conversations');
    console.log('Sort option:', sortOption);
    console.log('Sort direction:', sortDirection);
    console.log('Show only my chats:', showOnlyMyChats);
    console.log('Current user:', loginData.user?.name);

    const filteredConversations = showOnlyMyChats
      ? conversations.filter(conv => {
        const hasUserMessage = conv.messages.some(msg => msg.sender === loginData.user?.name);
        console.log(`Conversation ${conv.id} has user message:`, hasUserMessage);
        return hasUserMessage;
      })
      : conversations;

    // Exclude new conversations
    const nonNewConversations = filteredConversations.filter(
      conv => !newConversations.some(newConv => newConv.id === conv.id)
    );

    console.log('Filtered conversations:', nonNewConversations.map(c => c.id));

    const sortedConversations = [...nonNewConversations];

    if (sortOption === 'name') {
      sortedConversations.sort((a, b) => {
        const result = (a.name || '').localeCompare(b.name || '');
        return sortDirection === 'asc' ? result : -result;
      });
    } else if (sortOption === 'date') {
      sortedConversations.sort((a, b) => {
        const aDate = new Date(a.last_edited || 0);
        const bDate = new Date(b.last_edited || 0);
        const result = bDate.getTime() - aDate.getTime();
        return sortDirection === 'asc' ? result : -result;
      });
    } else {
      // For unsorted state, reverse the order
      sortedConversations.reverse();
    }

    console.log('Sorted conversations:', sortedConversations.map(c => c.id));
    return sortedConversations;
  }, [conversations, sortOption, sortDirection, showOnlyMyChats, loginData.user?.name, newConversations]);

  useEffect(() => {
    console.log('Conversations updated:', conversations.map(c => c.id));
  }, [conversations]);

  if (isLoading) return <Box>Loading conversations...</Box>;
  if (error) return <Box color="red.500">{error}</Box>;

  return (
    <Box p={4} bg={bgColor} color={textColor} minH="100vh">
      <HStack justifyContent="space-between" mb={4}>
        <Heading as="h2" size="lg">Chatliste</Heading>
        <Button
          leftIcon={<AddIcon />}
          bg={buttonColor}
          _hover={{ bg: buttonHoverColor }}
          onClick={handleCreateNewConversation}
        >
          Neuer Chat
        </Button>
      </HStack>
      <HStack spacing={4} mb={4}>
        <HStack>
          <Button
            onClick={() => handleSort('unsorted')}
            bg={sortOption === 'unsorted' ? buttonColor : activeButtonColor}
            _hover={{ bg: buttonHoverColor }}
          >
            Unsortiert
          </Button>
          <Button
            onClick={() => handleSort('name')}
            bg={sortOption === 'name' ? buttonColor : activeButtonColor}
            _hover={{ bg: buttonHoverColor }}
            rightIcon={sortOption === 'name' ? (sortDirection === 'asc' ? <TriangleUpIcon /> : <TriangleDownIcon />) : undefined}
          >
            A-Z
          </Button>
        </HStack>
        <HStack>
          <Switch
            isChecked={showOnlyMyChats}
          // onChange={(e) => setShowOnlyMyChats(e.target.checked)}
          />
          <Text>Nur meine Chats</Text>
        </HStack>
      </HStack>
      {newConversations.length > 0 && (
        <Box mb={4} borderWidth={1} borderRadius="md" p={3}>
          <Heading as="h3" size="md" mb={2}>Neue Chats</Heading>
          <VStack spacing={2} align="stretch">
            {newConversations.map(conversation => (
              <ChatSelectionElement
                key={conversation.id}
                conversation={conversation}
                onActionComplete={onActionComplete}
                isNewConversation={true}
                onRemoveFromNew={removeFromNewConversations}
              />
            ))}
          </VStack>
        </Box>
      )}
      <VStack spacing={2} align="stretch">
        {sortedAndFilteredConversations.map(conversation => (
          <ChatSelectionElement
            key={conversation.id}
            conversation={conversation}
            onActionComplete={onActionComplete}
          />
        ))}
      </VStack>
    </Box>
  );
};

export default ConversationsListPage;