// NotificationContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';

// Define the type for your notification context
type NotificationContextType = {
  addNotification: (notif: Notif) => void;
  notificationHistory: Notif[];
  currentNotifications: Notif[];
};

type Notif = {
  title: string;
  message: string;
  status: 'error' | 'info' | 'warning' | 'success';
};

// Create the context
const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

// Provider component
export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [notificationHistory, setNotificationHistory] = useState<Notif[]>([]); // state for tracking notification history
  const [currentNotifications, setCurrentNotifications] = useState<Notif[]>([]); // state for tracking current notifications
  const toast = useToast();

  const addNotification = (notif: Notif) => {
    // Use Chakra UI's toast for showing error notifications
    toast({
      title: notif.title,
      description: notif.message,
      status: notif.status,
      duration: 5000,
      isClosable: true,
    });

    // Add the notification to the history
    setNotificationHistory([...notificationHistory, notif]);

    // Add the notification to the current notifications
    setCurrentNotifications([...currentNotifications, notif]);

    // Remove the notification after 5 seconds
    setTimeout(() => {
      setCurrentNotifications(currentNotifications.filter(item => item !== notif));
    }, 5000);
  };

  return (
    <NotificationContext.Provider value={{ addNotification, notificationHistory, currentNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Axios wrapper function
export const useAxiosWrapper = () => {
  const { addNotification } = useNotification();

  const axiosInstance = axios.create({
    // Your axios configuration here

  });

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response => {
      console.log('Response:', response);
      return response;
    },
    error => {
      let message;
      switch (error.response.status) {
        case 402:
          message = "Your budget was reached! Please contact us for an increase!"
          break;
      }
      // Handle the error
      addNotification({
        title: `Error ${error.response.status}: ${error.response.statusText}`,
        message: message ?? error.message,
        status: 'error',
      });
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

// Consumer hook
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
